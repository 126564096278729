import React, { Fragment, useContext } from "react";
import styled, { css, ThemeContext } from "styled-components";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { CodeBlock2, Paragraph, Blockquote, Image, InlineCode, OrderedList } from "../markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faStopwatch, faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import TableOfContents from "../table-of-contents";
import Meta from "./meta";

const Neumorphic = css`
  box-shadow: 2px 2px 3px #00000040, // bottom right
              inset 1px 1px 1px #ffffff0a, // top left edge
              inset 5px 5px 10px #ffffff05; // top left shine
`;

const Heading = ({ headingLevel, className, children, ...props }) => {
  const HeadingTag = `h${headingLevel}`;

  return (
    <HeadingTag className={className} {...props}>
      {children}
    </HeadingTag>
  );
};

const S = {
  CoverImage: styled(GatsbyImage)`
    ${Neumorphic}

    border-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-height: 40vh;
    z-index: -1;
  `,
  Title: styled.h1`
    font-size: 2.5rem;
    margin: 0;
  `,
  Subtitle: styled.p`
    font-size: 1.8rem;
  `,
  DatePublished: styled.p`
    font-size: 1rem;
  `,
  Divider: styled.hr`
    width: 100%;
  `,
  Heading2: styled(Heading)`
    & a.section-heading-link {
      display: inline-flex;
      align-items: center;
      height: 100%;

      & svg {
        fill: ${props => props.theme.text};
        width: 25px;
        height: 25px;
      }
    }
  `,
  PhotoCreditContainer: styled.div`
    ${Neumorphic}
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
    padding: 10px 15px;
    box-sizing: border-box;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: ${props => props.theme.body};
  `,
  Anchor: styled.a`
    cursor: pointer;
    text-decoration: underline;
  `,
};


const BlogPost = ({ postData }) => {
  const { theme } = useContext(ThemeContext);
  const heroImage = getImage(postData.frontmatter.hero_image);
  
  const components = {
    pre: props => <div {...props} />,
    code: props => <CodeBlock2 {...props} />,
    p: props => <Paragraph theme={theme} {...props} />,
    blockquote: props => <Blockquote {...props} />,
    h1: props => <S.Heading2 headingLevel={1} theme={theme} {...props} />,
    h2: props => <S.Heading2 headingLevel={2} theme={theme} {...props} />,
    h3: props => <S.Heading2 headingLevel={3} theme={theme} {...props} />,
    h4: props => <S.Heading2 headingLevel={4} theme={theme} {...props} />,
    h5: props => <S.Heading2 headingLevel={5} theme={theme} {...props} />,
    h6: props => <S.Heading2 headingLevel={6} theme={theme} {...props} />,
    a: ( { href, children, ...props } ) => <S.Anchor href={href} {...props}>{children}</S.Anchor>,
    img: ( { src, alt, ...props } ) =>  <Image src={src} alt={alt} {...props} />,
    inlineCode: props => <InlineCode theme={theme} {...props} />,
    ol: props => <OrderedList {...props} />,
  };

  return (
    <Fragment>
      <Meta postData={postData} />

      <S.Title>
        {postData.frontmatter.title}
      </S.Title>

      { postData.frontmatter.hero_image_credit_link && postData.frontmatter.hero_image_credit_text 
        ? <S.CoverImage 
            image={heroImage} 
            alt={postData.frontmatter.hero_image_alt}
          />
        : <S.CoverImage 
            image={heroImage} 
            alt={postData.frontmatter.hero_image_alt}
            style={{ borderRadius: "15px" }}
          />
      }
      
      { postData.frontmatter.hero_image_credit_link && postData.frontmatter.hero_image_credit_text &&
        <S.PhotoCreditContainer theme={theme}>
          <a 
            href={postData.frontmatter.hero_image_credit_link}
            target="_blank" 
            rel="noreferrer"
            style={{ display: "inline-block" }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <FontAwesomeIcon icon={faCameraRetro} />
              <p>Photo Credit: {postData.frontmatter.hero_image_credit_text}</p>
            </div>
          </a>
        </S.PhotoCreditContainer>
      }

      <S.Subtitle>
        {postData.frontmatter.subtitle}
      </S.Subtitle>

      <div style={{ display: "flex", gap: "25px", flexWrap: "wrap" }}>
        <div style={{ display: "flex", gap: "10px" }}>
          <FontAwesomeIcon icon={faStopwatch} />
          <p>
            Time to read: ~{postData.timeToRead} minutes
          </p>
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          <FontAwesomeIcon icon={faCalendarAlt} />
          <S.DatePublished>
            Published: {postData.frontmatter.date}
          </S.DatePublished>
        </div>
      </div>

      <S.Divider />

      {
        postData.tableOfContents.items &&
        <TableOfContents 
          headings={postData.tableOfContents}
          theme={theme}
        />
      }

      <MDXProvider components={components}>
        <MDXRenderer>
          {postData.body}
        </MDXRenderer>
      </MDXProvider>
    </Fragment>
  );
};

export default BlogPost;