import styled, { css } from "styled-components";
import { Prism } from "react-syntax-highlighter";

const Neumorphic = css`
  box-shadow: 2px 2px 3px #00000040, // bottom right
              inset 1px 1px 1px #ffffff0a, // top left edge
              inset 5px 5px 10px #ffffff05; // top left shine
`;

const SyntaxHighlighter = styled(Prism)`
  ${Neumorphic}

  padding: 25px 25px 0 25px !important;
  position: relative;
  border-radius: 15px;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const InlineCode = styled.code`
  ${Neumorphic}
  background-color: ${props => props.theme.codeHighlightBackground};
  color: ${props => props.theme.codeHighlightText};
  padding: 2px 8px;
  border-radius: 8px;
  line-height: 1.5rem;
`;

const Paragraph = styled.p`
  margin: 0;
  font-size: 1.2rem;
  line-height: 2rem;

  // for gifs
  img {
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    max-width: 100%;
  }
`;

const Image = styled.img`
  ${Neumorphic}
  display: block;
  margin: 0 auto;
  max-width: 100%;
  border-radius: 15px;
  width: unset !important;
  height: unset !important;
`;

const Gif = styled.img`
  ${Neumorphic}
  display: block;
  margin: 0 auto;
  max-width: 100%;
  border-radius: 15px;
`;

const Blockquote = styled.blockquote`
  border-left: 5px solid ${props => props.theme.text};
  padding-left: 25px;
`;

const Heading1 = styled.h1`
  font-size: 2.5rem;
`;

const Heading2 = styled.h2`
  font-size: 2rem;
`;

const OrderedList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
`;

const CodeBlockTab = styled.div`
  position: absolute;
  top: -30px; // required to sit above code block
  z-index: 2;
  width: 100%;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  border-bottom-left-radius: ${ props => props.isCodeBlockVisible ? 0 : '0.3em' };
  border-bottom-right-radius: ${ props => props.isCodeBlockVisible ? 0 : '0.3em' };
  padding: 10px 25px;
  box-sizing: border-box;
  background-color: #0B0F12;
  color: #E4E3EF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 40px;
`;

const AnchorButton = styled.button`
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  // so that button content doesn't extend to a second line
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export {
  SyntaxHighlighter,
  InlineCode,
  Paragraph,
  Image,
  Gif,
  Blockquote,
  Heading1,
  Heading2,
  OrderedList,
  CodeBlockTab,
  AnchorButton
};