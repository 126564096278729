import React, { Fragment } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/shared/layout";
import BlogPost from "../../components/blog-post";

const BlogPostPage = ({ data }) => {
  return (
    <Fragment>
      <Layout pageTitle={data.mdx.frontmatter.title}>
        <BlogPost postData={data.mdx} />
      </Layout>
    </Fragment>
  );
};

export const blogPostQuery = graphql`
  query FetchBlogPost($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        date(formatString: "dddd, MMMM Do YYYY")
        title
        subtitle
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1.78, 
              transformOptions: { 
                cropFocus: CENTER 
              }
            )
          }
        }
      }
      body
      tableOfContents
      timeToRead
      slug
    }
  }
`;

export default BlogPostPage;