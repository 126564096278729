import styled, { css } from "styled-components";

const Neumorphic = css`
  box-shadow: 2px 2px 3px #00000040, // bottom right
              inset 1px 1px 1px #ffffff0a, // top left edge
              inset 5px 5px 10px #ffffff05; // top left shine
`;

const TableOfContentsContainer = styled.div`
  ${Neumorphic}

  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.tableOfContentsBorder};
  border-radius: 15px;
  padding: 25px 25px 10px 25px;
`;

const TableOfContentsTitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 1.5rem;
`;

const TableOfContentsTitle = styled.h2`
  font-size: 1.5rem;
`;

const TableOfContentsList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

export {
  TableOfContentsContainer,
  TableOfContentsTitleContainer,
  TableOfContentsTitle,
  TableOfContentsList,
};