import React from "react";
import * as S from "./table-of-contents.styles";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

const TableOfContents = ({ headings, theme }) => {
  const renderItems = (items) =>{
    return (
      <S.TableOfContentsList>
        {items.map((item) => {
          const title = item.title.replace(/^\d+\.\s/, "");

          return (
            <li key={item.title}>
              <Link to={item.url}>{title}</Link>
              {item.items && renderItems(item.items)}
            </li>
          );
        })}
      </S.TableOfContentsList>
    )
  };

  return (
    <S.TableOfContentsContainer 
      id="table-of-contents"
      theme={theme}
    >
      <S.TableOfContentsTitleContainer>
        <FontAwesomeIcon icon={faList} />
        <S.TableOfContentsTitle>
          Table of Contents
        </S.TableOfContentsTitle>
      </S.TableOfContentsTitleContainer>
      
      {renderItems(headings.items)}
    </S.TableOfContentsContainer>
  )
};

export default TableOfContents;