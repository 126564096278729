import React from "react";
import * as S from "./markdown.styles";

const OrderedList = ({ children, ...props }) => {
  return (
    <S.OrderedList {...props}>
      {children}
    </S.OrderedList>
  );
};

export default OrderedList;