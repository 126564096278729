import React, { useEffect, useState } from "react";
import * as S from "./markdown.styles";
import { dracula as codeBlockTheme } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

const CodeBlock2 = ({ className, children, ...props }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [language, setLanguage] = useState();
  const [isHideable, setIsHideable] = useState();
  const [filename, setFilename] = useState();
  
  useEffect(() => {
    const properties = parseClassNameIntoProperties(className);
    setIsHideable(properties.hideable);
    setLanguage(properties.language);
    setFilename(properties.filename);
  }, [className]);

  const parseClassNameIntoProperties = (className) => {
    const classNames = className.replace(/language-/, '').split(',');

    let properties = {};
    classNames.forEach((className) => {
      const [key, value] = className.split('=');
      properties[key] = value;
    });

    return properties;
  };

  const shouldRenderTab = () => {
    return isHideable || filename;
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return(
    <div style={{ position: 'relative', marginTop: shouldRenderTab() ? '30px' : 0 }}>
      { 
        shouldRenderTab() &&
        <S.CodeBlockTab isCodeBlockVisible={isVisible} >
          { filename && 
            (isHideable 
              ? <S.AnchorButton style={{ cursor: 'pointer' }} onClick={toggleVisibility}>{filename}</S.AnchorButton>
              : <p>{filename}</p>
            )
          }
          {isHideable && 
            <S.AnchorButton style={{ cursor: 'pointer' }} onClick={toggleVisibility}>
              {isVisible 
                ? <span aria-label="Hide Code Snippet">
                    <FontAwesomeIcon icon={faEyeSlash}/>
                  </span>
                : <span aria-label="Show Code Snippet">
                    <FontAwesomeIcon icon={faEye}/>
                  </span>
              }
            </S.AnchorButton>}
        </S.CodeBlockTab>
      }
      { isVisible ? (
        <S.SyntaxHighlighter 
            className={className} 
            language={language} 
            style={codeBlockTheme}
            PreTag="div"
            {...props}
        >
            {children}
        </S.SyntaxHighlighter>
      ) : <div style={{ margin: '0.5rem 0' }}></div> 
      }
    </div>
  );
};

export default CodeBlock2;