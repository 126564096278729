import React, { Fragment } from "react";
import * as S from "./markdown.styles";

const Image = ({ src, alt, ...props }) => {
  const renderImage = () => {
    if (src.endsWith(".gif"))
      return <S.Gif src={src} alt={alt} {...props} />;

    return <S.Image src={src} alt={alt} {...props} />;
  };

  return (
    <Fragment>
      {renderImage()}
    </Fragment>
  );
};


export default Image;