import React from 'react';
import * as S from './markdown.styles';


const Paragraph = ({ className, children, ...props }) => {
  return (
    <S.Paragraph 
      className={className}
      {...props}
    >
      {children}
    </S.Paragraph>
  );
};

export default Paragraph;