import React, { useContext } from "react";
import * as S from "./markdown.styles";
import { ThemeContext } from "styled-components";

const Blockquote = ({ children, ...props }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <S.Blockquote 
      theme={theme}
      {...props}
    >
      {children}
    </S.Blockquote>
  );
};

export default Blockquote;