import React from "react";
import * as S from "./markdown.styles";

const InlineCode = ({ theme, children }) => {
  return (
    <S.InlineCode
      theme={theme}
    >
      {children}
    </S.InlineCode>
  );
};

export default InlineCode;