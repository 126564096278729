import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

const siteMetadataQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        blogUrl
      }
    }
  }
`;

const Meta = ({ postData }) => {
  const { site } = useStaticQuery(siteMetadataQuery);

  const metadata = {
    title: postData.frontmatter.title,
    description: postData.frontmatter.subtitle,
    imageUrl: `${site.siteMetadata.siteUrl}${postData.frontmatter.hero_image.childImageSharp.gatsbyImageData.images.fallback.src}`,
    imageAlt: postData.frontmatter.hero_image_alt,
    postUrl: `${site.siteMetadata.blogUrl}${postData.slug}`,
    pageType: "article",
    twitterCard: "summary_large_image",
    twitterSite: "@theandrewgrass",
    twitterCreator: "@theandrewgrass",
  };

  const metaTags = [
    { property: "og:title", content: metadata.title },
    { property: "og:description", content: metadata.description },
    { property: "og:image", content: metadata.imageUrl },
    { property: "og:secure_url", content: metadata.imageUrl },
    { property: "og:image:alt", content: metadata.imageAlt },
    { property: "og:url", content: metadata.postUrl },
    { property: "og:type", content: metadata.pageType },
    { name: "twitter:card", content: metadata.twitterCard },
    { name: "twitter:title", content: metadata.title },
    { name: "twitter:description", content: metadata.description },
    { name: "twitter:image", content: metadata.imageUrl },
    { name: "twitter:image:alt", content: metadata.imageAlt },
    { name: "twitter:url", content: metadata.postUrl },
    { name: "twitter:site", content: metadata.twitterSite },
    { name: "twitter:creator", content: metadata.twitterCreator },
  ];

  return (
    <Helmet
      link={[ { rel: "canonical", href: metadata.postUrl } ]}
      meta={metaTags}
    />
  );
};

export default Meta;